<template>
  <column-picker
    :selected-col-id.sync="computedCol"
    :filters="filters"
    :label="$t(`t.Selector`)"
    :attach="false"
    :hide-details="true"
    :show-icon="true"
    clearable
    currency-disabled
  />
</template>

<script>
export default {
  components: {
    ColumnPicker: () => import('@/components/column-picker')
  },
  computed: {
    computedCol: {
      get () { return this.dataCol },
      set (v) {
        this.dataCol = v
        this.dataDocumentEmit()
      }
    },
    filters () {
      return { 'document-types': [[`${this.targetDocTy}`], []] }
    }
  },
  data () {
    return {
      dataCol: null,
      dataType: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  methods: {
    emitDocument () {
      const doc = { col: this.dataCol }

      if (!this.lodash.isEqual(doc, this.document)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    }
  },
  props: {
    document: Object,
    targetDocTy: String
  },
  watch: {
    document: {
      handler (v) {
        this.dataCol = v?.col ?? null
        this.dataDocumentEmit()
      },
      immediate: true
    },
    targetDocTy: {
      handler (v) {
        if (v) {
          this.computedCol = null
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped></style>
